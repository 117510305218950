<template>
  <b-form @submit.stop.prevent>
    <h3>{{ $t('booking.internal-info-title') }}</h3>
    <b-form-group id="input-group-1" :label="`${$t('booking.clerk-name')}:`" label-for="input-1">
      <b-form-input
        id="input-1"
        v-model="clerkName"
        type="text"
        disabled
      ></b-form-input>
    </b-form-group>

    <b-form-group id="input-group-2" :label="`${$t('booking.agent-list')}:`" label-for="input-2">
      <!-- <v-select
        v-model="agent"
        :reduce="agent => agent.value"
        :options="agentList"
        dir="rtl"
        :clearable="false"
        :searchable="true"
      >
      </v-select> -->
      <b-form-input
        id="input-2"
        v-model="agent"
        type="text"
        disabled
      ></b-form-input>
    </b-form-group>
    <b-form-group id="input-group-3" :label="`${$t('booking.discount-amount')}:`" label-for="input-3">
      <b-form-input
        id="input-1"
        v-model="salesAmount"
        type="text"
        class="agent-amount"
        :disabled="loginOdyAgentCode!==changeEnableAgentCode"
      ></b-form-input>
        <!-- :state="!!Number(salesAmount.substr(1))" -->
    </b-form-group>

    <b-form-group id="input-group-5" :label="`${$t('booking.remark-by-agent')}:`" label-for="input-5">
      <b-form-textarea
        id="input-5"
        v-model="agentRemark"
        rows="3"
        max-rows="6"
        :placeholder="$t('booking.remark-by-agent')"
        @change="changeRemark"
      ></b-form-textarea>
    </b-form-group>

    <b-form-group id="input-group-4">
      <b-form-checkbox v-model="bypassPayment" :value="true">{{ $t('booking.bypass-payment') }}</b-form-checkbox>
    </b-form-group>
  </b-form>
</template>

<script>
import { mapGetters } from 'vuex';
import 'vue-select/dist/vue-select.css';
import { BForm, BFormGroup, BFormInput, BFormCheckbox, BFormTextarea } from 'bootstrap-vue';
import gMixin from '@/utils/mixins';

const { VUE_APP_ENABLE_AGENT_CODE } = process.env;

export default {
  name: 'InternalAgentInformation',
  mixins: [gMixin],
  components: {
    BForm, BFormGroup, BFormInput, BFormCheckbox, BFormTextarea,
  },
  props: {
    currency: {
      type: String,
      default: 'US',
    },
    fullPrice: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      agent: '',
      salesAmount: '',
      agentRemark: '',
      initialBalance: 0,
      bypassPayment: false,
      changeEnableAgentCode: VUE_APP_ENABLE_AGENT_CODE,
    };
  },
  computed: {
    ...mapGetters({
      allAgentList: 'GET_ALL_AGENT_LIST',
      agentContent: 'GET_ODYSSEY_AGENT_CONTENT',
      clerkName: 'GET_ODYSSEY_AGENT_CLERK_NAME',
      odyAgentCode: 'GET_SELECTED_ODYSSEY_AGENT_CODE',
      loginOdyAgentCode: 'GET_LOGIN_ODYSSEY_AGENT_CODE',
    }),
    agentList() {
      const list = this.allAgentList;
      if (list.length === 0) return [];
      return list.map((elm, inx) => ({ id: inx, value: elm.agt_Code, label: `${elm.agt_Code} - ${elm.agt_Name}` }));
    },
    // salesAmount: {
    //   get() {
    //     return this.getPriceWithSymbol(this.currency, this.initialBalance);
    //   },
    //   set(newValue) {
    //     this.initialBalance = Number(newValue.split(/ /)[0].replace(/[^\d]/g, ''));
    //   },
    // },
  },
  watch: {
    bypassPayment() {
      this.update();
      this.$store.commit('SET_ODYSSEY_BYPASS_PAYMENT', this.bypassPayment);
    },
    // salesAmount() {
    //   this.update();
    // },
  },
  async created() {
    await this.$store.dispatch('FETCH_ALL_AGENT_LIST');
  },
  mounted() {
    this.agent = this.odyAgentCode;
    this.salesAmount = this.getPriceWithSymbol(this.currency, this.fullPrice);
    this.update();
  },
  methods: {
    update() {
      this.$emit('updateInternalAgentInfo', {
        clerkName: this.clerkName,
        OdyAgentCodeByAgent: this.agent,
        SalesAmount: Number(this.salesAmount.substr(1)),
        BypassPaymentByAgent: this.bypassPayment,
        remarkByAgent: this.agentRemark,
      });
    },
    changeRemark() {
      this.update();
    },
  },
};
</script>
<style scoped>
  .agent-amount{
    background-position: left calc(0.375em + 0.1875rem) center;
    padding-left: calc(1.5em + 0.75rem);
    padding-right: 12px;
  }
</style>
